import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import '../styles/PageNotFound.css'; // Create a CSS file for styling

function PageNotFound() {
  return (
    <div className="page-not-found">
      <div className="content">
        <h1>404</h1>
        <h2>Oops! Page Not Found</h2>
        <p>The page you're looking for doesn't exist or has been moved.</p>
        <Link to="/" className="home-link">Go Back to Home</Link>
      </div>
    </div>
  );
}

export default PageNotFound;
