import React, { useEffect } from "react";
import { FiUser } from "react-icons/fi";
import { GrTechnology } from "react-icons/gr";
import AOS from "aos";
import "aos/dist/aos.css";

function ServiceArea() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
      <section className="service-area-2 white-bg pt-120 z-index">
        <div className="container">
          <div className="row">
            <div className="col-xl-6" data-aos="fade-up" style={{marginBottom:"60px"}}>
              <div className="aservice--2 mb-30">
                <div
                  className="section-title-wrapper mb-50 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <h2 className="section-title">
                  Dependable and Personalized 
                    <br />
                    <span> Repair Services</span>
                  </h2>
                </div>
                <div
                  className="aservice__list mb-55 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="aservice__list--icon">
                    <FiUser />
                  </div>
                  <div className="aservice__list--text">
                    <h4 className="aservice__list--text__title">
                      <a href="service-details.html">Personal Technician</a>
                    </h4>
                    <p>
                      Experience dedicated service with a personal technician
                      assigned to every job. Our experts ensure thorough
                      diagnostics and precise repairs for your AC units,
                      ensuring optimal performance and reliability..{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="aservice__list mb-55 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <div className="aservice__list--icon">
                    <div>
                      <GrTechnology />
                    </div>
                  </div>
                  <div className="aservice__list--text">
                    <h4 className="aservice__list--text__title">
                      <a href="service-details.html">Samart Technology</a>
                    </h4>
                    <p>
                      We integrate smart technology solutions into our repair
                      services, enhancing efficiency and performance for your
                      appliances. Benefit from advanced diagnostics and remote
                      monitoring capabilities.
                    </p>
                  </div>
                </div>
                <div
                  className="aservice__list wow fadeInUp"
                  data-wow-delay="1.2s"
                >
                  <div className="aservice__list--icon">
                    <div>
                      <GrTechnology />
                    </div>
                  </div>
                  <div className="aservice__list--text">
                    <h4 className="aservice__list--text__title">
                      <a href="service-details.html">Affordable Package</a>
                    </h4>
                    <p>
                      Choose from our affordable service packages designed to
                      meet your specific needs and budget. Enjoy transparent
                      pricing and comprehensive solutions for AC repairs,
                      installations, and maintenance..{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6" data-aos="fade-up">
              <div
                className="aservice--img__2 mb-30 wow fadeInUp"
                data-wow-delay="1.5s"
              >
                <img
                  style={{ width: "100%" }}
                  src="/assets/ac_repair.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceArea;
