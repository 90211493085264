import React from "react";
import { Col, Row } from "react-bootstrap";

const ACInstallation = () => (
  <div>
    <h2>AC Installation in Noida</h2>
    <p>
      Ensure your home or office stays cool and comfortable with our
      professional AC installation services in Noida. Our expert technicians
      provide efficient and reliable installation for all types of air
      conditioners, ensuring optimal performance and longevity.
    </p>
    <img
      src="/assets/service/more-services/ac_ installation.png"
      alt="AC Installation"
    />
    <p>
      Our AC installation process is thorough and meticulous, starting with a
      comprehensive assessment of your space to determine the best placement and
      installation method. We use high-quality materials and the latest
      techniques to guarantee a seamless installation, minimizing any disruption
      to your daily routine.
    </p>
    <p>
      Whether you need a split AC, window AC, or central air conditioning system
      installed, our team has the expertise to handle it all. We also offer
      guidance on selecting the right AC unit for your specific needs, ensuring
      you get the best cooling solution for your space.
    </p>
    <h3>Service Overview</h3>
    <p>Our AC installation services in Noida include:</p>
    <ul>
      <li style={{listStyleType:"disc"}}>Assessment of your cooling needs and space requirements</li>
      <li  style={{listStyleType:"disc"}}>Professional installation of all types of air conditioning units</li>
      <li  style={{listStyleType:"disc"}}>Post-installation testing and quality checks</li>
      <li  style={{listStyleType:"disc"}}>Guidance on AC maintenance and care for optimal performance</li>
    </ul>
    <Row>
      <Col md={6}>
        <img
          src="/assets/service/more-services/ac_ installation_1.png"
          alt="AC Installation"
        />
      </Col>
      <Col md={6}>
        <img
          src="/assets/service/more-services/ac_ installation_2.png"
          alt="AC Installation"
        />
      </Col>
    </Row>
   
  </div>
);

export default ACInstallation;
