import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/mamenu.css";
import { FaBars, FaFacebook, FaInstagram, FaTimes } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { BsGoogle } from "react-icons/bs";
import GetInTouchModal from "./GetInTouchModal";
import { IoMdCall } from "react-icons/io";
function Navbar() {
  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll < 60) {
        document
          .querySelector(".header-sticky")
          .classList.remove("sticky-menu");
      } else {
        document.querySelector(".header-sticky").classList.add("sticky-menu");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const mobileMenu = document.getElementById("mobile-menu");
    const sideToggle = document.querySelector(".side-toggle");
    const sideInfo = document.querySelector(".side-info");
    const offcanvasOverlay = document.querySelector(".offcanvas-overlay");
    const sideInfoClose = document.querySelector(".side-info-close");

    const meanMenu = () => {
      if (window.innerWidth <= 991) {
        mobileMenu.style.display = "block";
      } else {
        mobileMenu.style.display = "";
      }
    };

    const handleSideToggle = () => {
      sideInfo.classList.add("info-open");
      offcanvasOverlay.classList.add("overlay-open");
    };

    const handleSideClose = () => {
      sideInfo.classList.remove("info-open");
      offcanvasOverlay.classList.remove("overlay-open");
    };

    meanMenu();
    window.addEventListener("resize", meanMenu);

    sideToggle.addEventListener("click", handleSideToggle);
    sideInfoClose.addEventListener("click", handleSideClose);
    offcanvasOverlay.addEventListener("click", handleSideClose);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", meanMenu);
      sideToggle.removeEventListener("click", handleSideToggle);
      sideInfoClose.removeEventListener("click", handleSideClose);
      offcanvasOverlay.removeEventListener("click", handleSideClose);
    };
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const pathnames = useLocation();
  const pathname = pathnames?.pathname;
  return (
    <div>
      <header>
        {/* <div className="header-top black-soft-bg d-none d-lg-block">
          <TopHeader />
        </div> */}
        <div className="header-menu header-sticky header-menu-2">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-3 col-8">
                <div className="header-logo  ">
                  <Link to="/">
                    <img
                      style={{
                        width: "120px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                      src="/Logo.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-4">
                <div className="main-menu d-none d-lg-block">
                  <nav id="mobile-menu" style={{ display: "block" }}>
                    <ul>
                      <li style={{ listStyle: "none" }}>
                        <Link
                          to="/"
                          style={{ color: pathname === "/" ? "#ff6600" : "" }}
                        >
                          HOME
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services"
                          style={{
                            color: pathname === "/services" ? "#ff6600" : "",
                          }}
                        >
                          SERVICES
                        </Link>
                      </li>
                      {/* <li>
                        <Link href="contact.html">PROJECT</Link>
                      </li>
                      <li>
                        <Link href="contact.html">BLOG</Link>
                      </li> */}
                      <li>
                        <Link
                          to="/about"
                          style={{
                            color: pathname === "/about" ? "#ff6600" : "",
                          }}
                        >
                          ABOUT
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          style={{
                            color: pathname === "/contact" ? "#ff6600" : "",
                          }}
                        >
                          CONTACT
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div
                  className="side-menu-icon d-lg-none text-end"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "flex-end",
                    float: "right",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "-4px",
                      padding: "5px 2px",
                      backgroundColor: "#ff6600",
                      color: "white",
                      borderRadius: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IoMdCall />
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        marginLeft: "2px",
                      }}
                      href="tel:9990292149"
                    >
                      Call Now
                    </a>
                  </span>
                  <button
                    className="side-toggle"
                    style={{ marginLeft: "10px" }}
                  >
                    <FaBars />
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 d-none d-lg-block ">
                <div className="header-right header-right-2 text-end">
                  <ul className="z-index">
                    <li onClick={handleShow}>
                      <a className="theme-btn ">Request Callback</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <>
        <div className="fix">
          <div className="side-info">
            <button className="side-info-close">
              <FaTimes style={{ color: "white" }} />
            </button>
            <div className="side-info-content">
              <div className="mobile-menu" />
              <div className="contact-infos mb-30">
                <div className="contact-list mb-30">
                  <nav id="mobile-menu" style={{ display: "block" }}>
                    <div>
                      <li style={{ listStyle: "none", paddingBottom: "10px" }}>
                        <Link
                          style={{
                            color: pathname === "/" ? "#ff6600" : "white",
                            textDecoration: "none",
                            paddingBottom: "10px",
                          }}
                          to="/"
                        >
                          HOME
                        </Link>
                      </li>
                      <li style={{ paddingBottom: "10px"  , listStyle:"none"}}>
                        <Link
                          style={{
                            color:
                              pathname === "/services" ? "#ff6600" : "white",
                            textDecoration: "none",
                            paddingBottom: "10px",
                          }}
                          to="/services"
                        >
                          SERVICES
                        </Link>
                      </li>
                      <li style={{ paddingBottom: "10px" , listStyle:"none" }}>
                        <Link
                          style={{
                            color: pathname === "/about" ? "#ff6600" : "white",
                            textDecoration: "none",
                            paddingBottom: "10px",
                          }}
                          to="/about"
                        >
                          ABOUT
                        </Link>
                      </li>
                      <li style={{ paddingBottom: "10px", listStyle:"none" }}>
                        <Link
                          style={{
                            color:
                              pathname === "/contact" ? "#ff6600" : "white",
                            textDecoration: "none",
                            paddingBottom: "10px",
                            listStyle:"none"
                          }}
                          to="/contact"
                        >
                          CONTACT
                        </Link>
                      </li>
                    </div>
                  </nav>
                  <h4 style={{ marginTop: "20px" }}>Contact Info</h4>
                  <div>
                    <li
                      style={{
                        color: "white",
                        listStyle: "none",
                        paddingBottom: "5px",
                      }}
                    >
                      <i className="flaticon-history" />
                      Monday to Sunday
                    </li>
                    <li
                      style={{
                        color: "white",
                        listStyle: "none",
                        paddingBottom: "5px",
                      }}
                    >
                      <i className="flaticon-pin" />
                      Noida, UP,201301
                    </li>
                    <li
                      style={{
                        color: "white",
                        listStyle: "none",
                        paddingBottom: "5px",
                      }}
                    >
                      <i className="fal fa-envelope" />
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href="mailto: repairox22@gmail.com"
                      >
                        <span
                          className="__cf_email__"
                          data-cfemail="2b42454d446b4a42595d42484e191f05484446"
                        >
                          repairox22@gmail.com
                        </span>
                      </a>
                    </li>
                  </div>
                  <div className="sidebar__menu--social">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61563744502075"
                    >
                      <FaFacebook />
                    </a>
                    <a target="_blank" href="https://x.com/repairox">
                      <FaTwitter />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/rapairox/?hl=en"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-overlay" />
      </>
      {show && (
        <GetInTouchModal
          show={show}
          handleClose={handleClose}
          setShow={setShow}
        />
      )}
    </div>
  );
}

export default Navbar;
