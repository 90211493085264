import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/BreadCrumbs";
import { Link, useLocation } from "react-router-dom";
import GetInTouchModal from "../components/GetInTouchModal";

function About() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow=()=>{
    setShow(true)
  }
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Breadcrumbs title={"ABOUT"} pagename={"Home"} />

      <section className="about-area pt-120 pb-90">
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="aabout-img-space">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="aabout-img img-lg mb-30 wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <img
                        src="/assets/about/about.png"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="aabout-img mb-30 wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <img
                        src="/assets/about/about-1.png"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div
                      className="aabout-img_text mb-30 black-soft-bg wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      <h2 className="aabout-img_text-title">
                        200+ <span>Project Done</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="aabout-text mr-150 mb-30">
                <div
                  className="section-title-wrapper mb-30 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <h6 className="subtitle mb-20">
                    {/* <img
                  src=""
                  className="img-fluid"
                  alt="img"
                />{" "} */}
                    About company
                  </h6>
                  <h2 className="section-title">
                    We are most popular
                    <br /> repair company
                  </h2>
                </div>
                <p className="mb-40 wow fadeInUp" data-wow-delay="1.1s">
                  <ul>
                    <li className="mb-2">
                      <strong>AC Repairing:</strong> Get your air conditioners
                      fixed promptly and efficiently.
                    </li>
                    <li className="mb-2">
                      <strong>AC Installation:</strong> Professional
                      installation services for all types of air conditioners.
                    </li>
                    <li className="mb-2">
                      <strong>AC on Rent:</strong> Convenient AC rental services
                      for your home or office.
                    </li>
                    <li className="mb-2">
                      <strong>Fridge Repairing:</strong> Reliable repair
                      services for all brands of refrigerators.
                    </li>
                    <li className="mb-2">
                      <strong>Washing Machine Repairing:</strong> Expert repairs
                      for washing machines of all models.
                    </li>
                    <li className="mb-2">
                      <strong>Microwave Repairing:</strong> Quick and efficient
                      microwave repair services.
                    </li>
                  </ul>
                </p>
                <div
                  className="aabout-qoute mb-50 wow fadeInUp"
                  data-wow-delay="1.3s"
                >
                  <i className="fas fa-quote-right float-bob-x" />
                  <h4 className="z-index">
                    Dont feel heat when there is best air <br />
                    conditioning seat{" "}
                  </h4>
                </div>
                <div
                  className="aabout--profile__wrapper wow fadeInUp"
                  data-wow-delay="2s"
                >
                  <div className="aabout--btn">
                    <Link to="#"  onClick={handleShow} className="theme-btn" style={{listStyle:"none", textDecoration:"none" , color:"white"}}>Request Callback</Link>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="feature-area about-feature-area pt-115 pb-165"
        data-background="assets/img/bg/feature-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-70 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h6 className="subtitle subtitle-border mb-20">How it works</h6>
                <h2 className="section-title">
                  Making technology again <br />
                  working for you
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="afeature text-center mb-30 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="mb-35">
                  <img
                    src="/assets/about/booking.png"
                    alt="img"
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div className="afeature__text">
                  <h4 className="afeature__text--title">
                    <a href="service-details.html">Booking Online</a>
                  </h4>
                  <p className="p-0">
                    Lorem ipsum dolor sit amets consec of tetur adipisicing elit
                    sed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="afeature text-center mb-30 wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className=" mb-35">
                  <img
                    src="/assets/about/communacation.png"
                    alt="img"
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div className="afeature__text">
                  <h4 className="afeature__text--title">
                    <a href="service-details.html">Discuss Budget</a>
                  </h4>
                  <p className="p-0">
                    Laboris nisi ut aliquip exe ea commodo the coniseqs duis
                    aute.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="afeature text-center mb-30 wow fadeInUp"
                data-wow-delay=".9s"
              >
                <div className=" mb-35">
                  <img
                    src="/assets/about/confirmation.png"
                    alt="img"
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div className="afeature__text">
                  <h4 className="afeature__text--title">
                    <a href="service-details.html">Get Confirmation</a>
                  </h4>
                  <p className="p-0">
                    Ut enim ade minim veniam quis nostrud exerci tation ullamco
                    laboris.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="afeature text-center mb-30 wow fadeInUp"
                data-wow-delay="1.2s"
              >
                <div className=" mb-35">
                  <img
                    src="/assets/about/customer-service.png"
                    alt="img"
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div className="afeature__text">
                  <h4 className="afeature__text--title">
                    <a href="service-details.html">Happy Services</a>
                  </h4>
                  <p className="p-0">
                    Suscipit doarce iaculis class nulam rutrume aliquams
                    vulputa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fact-area-3 about-fact-area-3 z-index">
        <div className="custom-container">
          <div className="theme-bg-blue about-theme-bg-blue pt-90 pb-65 z-index">
            <div className="row">
              <div className="col-xl-3 col-sm-6">
                <div
                  className="afact__3 mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="afact__3--icon">
                    <i className="flaticon-technician" />
                  </div>
                  <div className="afact__3--text">
                    <h2>280+</h2>
                    <span>Expert Technician</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div
                  className="afact__3 mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="afact__3--icon">
                    <i className="flaticon-business-and-trade" />
                  </div>
                  <div className="afact__3--text">
                    <h2>120+</h2>
                    <span>Total Branches</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div
                  className="afact__3 mb-30 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <div className="afact__3--icon">
                    <i className="flaticon-settings" />
                  </div>
                  <div className="afact__3--text">
                    <h2>380+</h2>
                    <span>Project Complete</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div
                  className="afact__3 mb-30 wow fadeInUp"
                  data-wow-delay="1.2s"
                >
                  <div className="afact__3--icon">
                    <i className="flaticon-quality" />
                  </div>
                  <div className="afact__3--text">
                    <h2>350+</h2>
                    <span>Happy Customer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="team-area about-team-area pt-260 pb-90"
        data-background="assets/img/bg/team--bg.jpg"
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-55 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h6 className="subtitle mb-20">
                  <img
                    src="assets/img/icon/subtitle-icon.png"
                    className="img-fluid"
                    alt="img"
                  />{" "}
                  Technician Team
                </h6>
                <h2 className="section-title">
                  Our dedicated &amp; expert <br /> team member
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="ateam about--ateam mb-30 fix wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="ateam__img mb-10">
                  <img src="/ac1.jpeg" className="img-fluid" alt="img" />
                  <div className="ateam__img--social">
                    <ul>
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-google" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ateam__text about--ateam__text">
                  <span>Main Technician</span>
                  <h4 className="ateam__text--title">
                    <a href="team-details.html">Demica Master</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="ateam about--ateam mb-30 fix wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="ateam__img mb-10">
                  <img src="/ac1.jpeg" className="img-fluid" alt="img" />
                  <div className="ateam__img--social">
                    <ul>
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-google" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ateam__text about--ateam__text">
                  <span>Junior Technician</span>
                  <h4 className="ateam__text--title">
                    <a href="team-details.html">Margie Burman</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="ateam about--ateam mb-30 fix wow fadeInUp"
                data-wow-delay=".9s"
              >
                <div className="ateam__img mb-10">
                  <img src="/ac1.jpeg" className="img-fluid" alt="img" />
                  <div className="ateam__img--social">
                    <ul>
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-google" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ateam__text about--ateam__text">
                  <span>Senior Technician</span>
                  <h4 className="ateam__text--title">
                    <a href="team-details.html">Gorrien Hyrick</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 d-lg-none d-xl-block col-sm-6">
              <div
                className="ateam about--ateam mb-30 fix wow fadeInUp"
                data-wow-delay="1.2s"
              >
                <div className="ateam__img mb-10">
                  <img src="/ac1.jpeg" className="img-fluid" alt="img" />
                  <div className="ateam__img--social">
                    <ul>
                      <li>
                        <a href="/">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fab fa-google" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ateam__text about--ateam__text">
                  <span>Founder Of Avc</span>
                  <h4 className="ateam__text--title">
                    <a href="team-details.html">Jonson Pierce</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {show && <GetInTouchModal show={show} handleClose={handleClose} setShow={setShow} />}
    </div>
  );
}

export default About;
