import React from 'react';
import { Col, Row } from 'react-bootstrap';

const FridgeRepairing = () => (
  <div>
    <h2>Fridge Repairing Service in Noida</h2>
    <p>
      Ensure your refrigerator is always in top working condition with our professional fridge repairing service in Noida. Our expert technicians are experienced in handling a wide range of refrigerator brands and models, providing efficient and reliable repair services to keep your appliance running smoothly.
    </p>
    <img src="/assets/service/more-services/fridge_2.png" alt="Fridge Repairing" />
    <p>
      Whether your fridge is not cooling properly, making unusual noises, or has any other issue, our team can diagnose and fix the problem promptly. We use genuine spare parts and advanced tools to ensure long-lasting repairs. With our service, you can avoid the inconvenience of a malfunctioning fridge and enjoy fresh and properly stored food.
    </p>
    <p>
      Regular maintenance is crucial to extending the lifespan of your refrigerator and ensuring it operates efficiently. Our maintenance services include thorough cleaning, inspection of all components, and necessary adjustments to keep your fridge in optimal condition. We also offer tips and guidance on how to care for your refrigerator to prevent future issues.
    </p>
    <h3>Service Overview</h3>
    <p>
      Our fridge repairing service in Noida includes:
    </p>
    <ul className="styled-list">
      <li  style={{listStyleType:"disc"}}>Diagnosis and troubleshooting of fridge problems</li>
      <li  style={{listStyleType:"disc"}}> Replacement of faulty components with genuine spare parts</li>
      <li  style={{listStyleType:"disc"}}>Regular maintenance and cleaning services</li>
      <li  style={{listStyleType:"disc"}}>Expert advice on fridge care and usage</li>
      <li  style={{listStyleType:"disc"}}>Prompt and reliable service at your convenience</li>
    </ul>
    <Row>
      <Col md={6}>
        <img src="/assets/service/more-services/fridge_1.png" alt="Fridge Repairing" />
      </Col>
      <Col md={6}>
        <img src="/assets/service/more-services/fridge_3.png" alt="Fridge Repairing" />
      </Col>
    </Row>
   
  </div>
);

export default FridgeRepairing;
