import React from 'react'
import Breadcrumbs from '../components/BreadCrumbs'
import VideoArea from '../components/VideoArea'
import ServiceTabs from '../components/ServiceTabs'

function Microwave() {
  return (
    <div><Breadcrumbs title={"Our Services"} pagename={"Home"} />
    <ServiceTabs active={"MicrowaveService"} />
    <VideoArea /></div>
  )
}

export default Microwave                 