import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, Toast, Spinner } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function GetInTouchModal({ show, handleClose, setShow }) {
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Mobile_Number: "",
    Description: "",
    Location: "",
  });
  const [formMessage, setFormMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Google Forms submission
    const googleFormsUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeYYgHAeOOmnJ5oJ2LzbMdX89hDY-aW6zzc5fOo9p7DU7CZdw/formResponse";
    const formParams = new URLSearchParams({
      "entry.2070711827": formData.Name,
      "entry.285480210": formData.Email,
      "entry.1361260351": formData.Mobile_Number,
      "entry.1709632575": formData.Location,
      "entry.738628135": formData.Description,
    }).toString();
    const fullUrl = `${googleFormsUrl}?${formParams}`;

    // EmailJS submission
    const emailParams = {
      name: formData.Name,
      email: formData.Email,
      mobile_number: formData.Mobile_Number,
      location: formData.Location,
      message: formData.Description,
    };

    // Google Forms submission
    fetch(fullUrl, {
      method: "POST",
      mode: "no-cors", // This is required to bypass CORS issues
    })
      .then((response) => {
        console.log("Form submitted successfully to Google Sheets");
      })
      .catch((error) => {
        console.error("Error submitting form to Google Sheets:", error);
        setFormMessage(
          "Error submitting form to Google Sheets. Please try again later."
        );
      });

    // EmailJS submission
    emailjs
      .send(
        "service_46j4rql", // Replace with your EmailJS service ID
        "template_xvzq1jo", // Replace with your EmailJS template ID
        emailParams,
        "DRmNTmTY1XrsmYHF2" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully via EmailJS!",
            response.status,
            response.text
          );
          setFormMessage("Form submitted successfully!");
          toast.success("Form submitted successfully!");
          handleClose();
          e.target.reset();
        },
        (error) => {
          console.error("Error sending email via EmailJS:", error.text);
          setFormMessage("Error sending email. Please try again later.");
          setIsLoading(false);
        }
      );
  };

  const Handleclose = () => {
    setShow(false);
  };
  const handleCloseToast = () => setShowToast(false);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <Row>
            <Col md={6}>
              <img
                src="/assets/banner.png"
                alt="Contact Us"
                className="img-fluid"
              />
            </Col>
            <Col md={6}>
              <div
                style={{
                  padding: "5px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <div>
                  <h3>Get In Touch</h3>
                </div>
                <div>
                  {" "}
                  <RxCrossCircled
                    onClick={Handleclose}
                    style={{
                      fontSize: "16px",
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              <Form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
                <Form.Group controlId="formName">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    required
                    onChange={handleChange}
                    name="Name"
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    required
                    onChange={handleChange}
                    name="Email"
                  />
                </Form.Group>
                <Form.Group controlId="formPhone">
                  <Form.Label>Your Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter your phone number"
                    required
                    onChange={handleChange}
                    name="Mobile_Number"
                  />
                </Form.Group>
                <Form.Group controlId="formLocation">
                  <Form.Label>Your Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your location"
                    required
                    onChange={handleChange}
                    name="Location"
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your message"
                    required
                    onChange={handleChange}
                    name="Description"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  style={{ width: "100%" }}
                >
                  {isLoading ? <Spinner /> : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Toast
        show={showToast}
        onClose={handleCloseToast}
        style={{ position: "fixed", top: 20, right: 20 }}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{formMessage}</Toast.Body>
      </Toast>
    </>
  );
}

export default GetInTouchModal;
