import React, { useEffect } from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
function Footer() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <footer data-background="/ac1.png" className="pt-30">
        <section className="footer-area pt-110 pb-85">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div
                  className="footer__widget mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <h4 className="footer__widget--title">About Us</h4>
                  <p>
                    we specialize in providing reliable and efficient solutions
                    for all your home appliance needs, including expert AC
                    repair and installation, fridge repair, and Washing machine
                    repair.
                  </p>
                  <div className="emg__number">
                    <h5>Emergency :</h5>
                    <h4>
                      <a href="tel:9990292149">+91 9990292149</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div
                  className="footer__widget mb-30 pl-80 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="footer__widget--title">Services</h4>
                  <div className="widget__links">
                    <li>
                      {" "}
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div
                  className="footer__widget mb-30 pl-30 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <h4 className="footer__widget--title">Contact Info</h4>
                  <div className="widget__contact">
                    <li> Gali No. 3, Agahpur, Sector 41 Shop No. 06  Near Glued Reloaded Restro,Noida, UP, 201301</li>
                    <li>
                      <span>Support:</span>{" "}
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="tel:9990292149"
                      >
                        +91 9990292149
                      </a>
                    </li>
                    <li>
                      <span>Email:</span>{" "}
                      <a
                        href="mailto: repairox22@gmail.com"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          className="__cf_email__"
                          data-cfemail="2b42454d446b4a42595d42484e191f05484446"
                        >
                          repairox22@gmail.com
                        </span>
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="copyright-area copyright-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="copyright__text">
                  <span>
                    Copyright © 2024{" "}
                    <a href="https://www.destinyitservices.com">
                      destinyitservices
                    </a>
                    . All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="copyright__social text-end">
                  <a href="https://www.facebook.com/profile.php?id=61563744502075">
                    <FaFacebookF />
                  </a>
                  <a href="https://x.com/repairox">
                    <FaXTwitter />
                  </a>
                  <a href="https://www.instagram.com/rapairox/?hl=en">
                    <FaInstagram />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
