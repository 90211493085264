import React, { useEffect } from 'react'
import HeroSection from '../components/HeroSection'
import Services from '../components/Services'
import AboutCompany from '../components/AboutCompany'
import VideoArea from '../components/VideoArea'
import ServiceArea from '../components/ServiceArea'
import { useLocation } from 'react-router-dom'
const servicesData = [
  {
    id: 1,
    title: "Ac Repairing",
    description: "Expert technicians provide reliable AC repair services for efficient cooling and optimal performance.",
    imgSrc: "/assets/service/1.png",
    delay: ".3s",
    link: "/services/ac-repair"
  },
  {
    id: 2,
    title: "Fridge Repairing",
    description: "Expert technicians offer reliable fridge repair services to ensure efficient cooling and optimal performance.",
    imgSrc: "/assets/service/4.png",
    delay: ".6s",
    link: "/services/fridge-repair"
  },
  {
    id: 3,
    title: "Washing Machine Repairing",
    description: "Expert technicians provide reliable washing machine repair services to ensure efficient performance and quick solutions.",
    imgSrc: "/assets/service/5.png",
    delay: ".9s",
    link: "/services/washing-machine-repair"
  },
  
  ];

function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  return (
    <div> <HeroSection />
    <Services data={servicesData} viewmore="View More Services" />
    {/* <BrandCompany /> */}
    <AboutCompany />
    <VideoArea />
    <ServiceArea /></div>
  )
}

export default Home