import React from 'react';
import { Col, Row } from 'react-bootstrap';

const WashingMachine = () => (
  <div>
    <h2>Washing Machine Repair and Service in Noida</h2>
    <p>
      Keep your washing machine running smoothly with our professional repair and maintenance services in Noida. Whether you have a front-loading, top-loading, or semi-automatic washing machine, our experienced technicians can handle all types of issues and ensure your appliance is in perfect working condition.
    </p>
    <img src="/assets/service/more-services/washing_machine.png" alt="Washing Machine Repair" />
    <p>
      Our team is equipped to handle a wide range of problems, from minor repairs to major overhauls. We use genuine spare parts and the latest tools to provide reliable and long-lasting solutions. With our prompt and efficient service, you can avoid the inconvenience of a malfunctioning washing machine and enjoy the convenience of clean clothes without any hassle.
    </p>
    <p>
      Regular maintenance is key to extending the lifespan of your washing machine and ensuring optimal performance. Our maintenance services include thorough cleaning, inspection of all components, and necessary adjustments to keep your appliance running efficiently. We also provide valuable tips and guidance on how to care for your washing machine to prevent future issues.
    </p>
    <h3>Service Overview</h3>
    <p>
      Our washing machine repair and service in Noida includes:
    </p>
    <ul className="styled-list">
      <li  style={{listStyleType:"disc"}}>Diagnosis and troubleshooting of washing machine problems</li>
      <li  style={{listStyleType:"disc"}}>Replacement of faulty components with genuine spare parts</li>
      <li  style={{listStyleType:"disc"}}>Regular maintenance and cleaning services</li>
      <li  style={{listStyleType:"disc"}}>Expert advice on washing machine care and usage</li>
      <li  style={{listStyleType:"disc"}}>Prompt and reliable service at your convenience</li>
    </ul>
    <Row>
      <Col md={6}>
        <img src="/assets/service/more-services/washing_machine _1.png" alt="Washing Machine Repair" />
      </Col>
      <Col md={6}>
        <img src="/assets/service/more-services/washing_machine _2.png" alt="Washing Machine Repair" />
      </Col>
    </Row>
  
  </div>
);

export default WashingMachine;
