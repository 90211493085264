import React, { useEffect } from "react";
import Services from "../components/Services";
import Breadcrumbs from "../components/BreadCrumbs";
import VideoArea from "../components/VideoArea";
import { useLocation } from "react-router-dom";
const servicesData = [
  {
    id: 1,
    title: "Ac Repairing",
    description: "Expert technicians provide reliable AC repair services for efficient cooling and optimal performance.",
    imgSrc: "/assets/service/1.png",
    delay: ".3s",
    link: "/services/ac-repair"
  },
  {
    id: 2,
    title: "Fridge Repairing",
    description: "Expert technicians offer reliable fridge repair services to ensure efficient cooling and optimal performance.",
    imgSrc: "/assets/service/4.png",
    delay: ".6s",
    link: "/services/fridge-repair"
  },
  {
    id: 3,
    title: "Washing Machine Repairing",
    description: "Expert technicians provide reliable washing machine repair services to ensure efficient performance and quick solutions.",
    imgSrc: "/assets/service/5.png",
    delay: ".9s",
    link: "/services/washing-machine-repair"
  },
  {
    id:4,
    title: "AC Installation Services",
    description:"Expert technicians provide professional AC installation services in Noida. Ensure optimal cooling efficiency and energy-saving solutions.",
    imgSrc: "/assets/service/2.png",
    delay: ".9s",
    link: "/services/ac-installaton"
  },
  {
    id:5,
    title: "Microwave Repair Services",
    description:"Expert technicians offer reliable microwave repair services for efficient performance and quick solutions.",
    imgSrc: "/assets/service/6.png",
    delay: ".9s",
    link: "/services/microwave"
  }
  ,
  {
    id:6,
    title: "AC Rental Services",
    description:"Explore our AC rental services for convenient cooling, ensuring comfort and efficiency for your home or office needs.",
    imgSrc: "/assets/service/3.png",
    delay: ".9s",
    link: "/services/ac-rental"
  }

];
function Service() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Breadcrumbs title={"Our Services"} pagename={"Home"} />
      <Services data={servicesData} />
      <VideoArea />
    </div>
  );
}

export default Service;
