import React, { useState } from "react";
import ACRepairing from "./Services/ACRepairing";
import ACInstallation from "./Services/ACInstallation";
import AconRent from "./Services/AconRent";
import "../styles/servicetab.css";
import { Col, Row } from "react-bootstrap";
import WashingMachine from "./Services/WashingMachine";
import FridgeRepairing from "./Services/FridgeRepairing";
import Microwave from "./Services/Microwave";
const ServiceTabs = ({ active }) => {
  const [selectedTab, setSelectedTab] = useState(active);
  console.log(active, "hrllo")

  const renderContent = () => {
    switch (selectedTab) {
      case "AcrepairingService":
        return <ACRepairing />;
      case "ACInstallation":
        return <ACInstallation />;
      case "AcOnRentService":
        return <AconRent />;
      case "WashineMachine":
        return <WashingMachine />;
      case "FridgeService":
        return <FridgeRepairing />;
        case "MicrowaveService":
          return <Microwave />;

      default:
        return <ACInstallation />;
    }
  };

  return (
    <div className="container">
      <div>
        <div>
          <Row>
            <Col>
              <div className="sidebar">
                <h4>Our Services</h4>{" "}
                <ul>
                  <li
                   
                    onClick={() => setSelectedTab("AcrepairingService")}
                  >
                    AC Repairing
                  </li>
                  <li
                   
                    onClick={() => setSelectedTab("ACInstallation")}
                  >
                    AC Installation
                  </li>
                  <li
                  
                    onClick={() => setSelectedTab("AcOnRentService")}
                  >
                    AC On Rent
                  </li>
                  <li
                   
                    onClick={() => setSelectedTab("WashineMachine")}
                  >
                    Washing Machine Repairing
                  </li>
                  <li
                   
                    onClick={() => setSelectedTab("FridgeService")}
                  >
                    Fridge Repairing
                  </li>
                  <li
                   
                   onClick={() => setSelectedTab("MicrowaveService")}
                 >
                  Microwave Repairing
                 </li>
                </ul>
              </div>
              <div className="sidebar">
                <h4>Contact Info</h4>{" "}
                <ul>
                  <li style={{lineHeight:"30px"}}>
                  Gali No. 3, Agahpur, Sector 41 Shop No. 06  Near Glued Reloaded Restro,Noida, UP, 201301
                  </li>
                  <li >
                  <a style={{color:"black"}} href="tel:9990292149">+91 9990292149</a>
                   
                  </li>
                  <li>
                  <a style={{color:"black"}} href="mailto: repairox22@gmail.com">
                        <span 
                          className="__cf_email__"
                          data-cfemail="2b42454d446b4a42595d42484e191f05484446"
                        >
                          repairox22@gmail.com
                        </span>
                      </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={8}>
              <div className="content">{renderContent()}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ServiceTabs;
