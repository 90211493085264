import React, { useEffect } from "react";
import Breadcrumbs from "../components/BreadCrumbs";
import ContactUsForm from "../components/ContactUsForm";
import { useLocation } from "react-router-dom";

function Contact() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {" "}
      <Breadcrumbs title={"CONTACT US"} pagename={"Home"} />
      <ContactUsForm />
      <div >
        <iframe
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          title="repairox"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d219.01679367220692!2d77.36133760469086!3d28.56169304266156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMzJzQyLjEiTiA3N8KwMjEnNDAuOSJF!5e0!3m2!1sen!2sin!4v1724493660557!5m2!1sen!2sin"        >
          <a href="https://www.gps.ie/">gps systems</a>
        </iframe>
      </div>
    </div>
  );
}

export default Contact;
