import React from 'react';
import { Col, Row } from 'react-bootstrap';

const AcRepairing = () => (
  <div>
    <h2>AC Repairing Services in Noida</h2>
    <p>
      Ensure your air conditioner is running smoothly with our professional AC repairing services in Noida. Our skilled technicians are experienced in diagnosing and fixing all types of AC issues, ensuring optimal performance and extending the lifespan of your unit.
    </p>
    <img src="/assets/service/more-services/ac_repairing.png" alt="AC Repairing" />
    <p>
      Our comprehensive AC repair services include everything from routine maintenance and cleaning to complex repairs and part replacements. We use high-quality parts and the latest tools to ensure your AC unit is restored to its optimal condition quickly and efficiently.
    </p>
    <p>
      No matter the brand or model of your air conditioner, our team can handle it all. We pride ourselves on our prompt and reliable service, minimizing downtime and ensuring your comfort is restored as soon as possible.
    </p>
    <h3>Service Overview</h3>
    <p>
      Our AC repairing services in Noida include:
    </p>
    <ul className="styled-list">
      <li style={{listStyleType:"disc"}}>Thorough inspection and diagnosis of AC issues</li>
      <li style={{listStyleType:"disc"}}>Cleaning and maintenance services</li>
      <li style={{listStyleType:"disc"}}>Repair and replacement of faulty parts</li>
      <li style={{listStyleType:"disc"}}>Performance testing and quality checks</li>
      <li style={{listStyleType:"disc"}}>Guidance on proper AC care and maintenance</li>
    </ul>
    <Row>
      <Col md={6}>
        <img src="/assets/service/more-services/ac_repairing_1.png" alt="AC Repairing" />
      </Col>
      <Col md={6}>
        <img src="/assets/service/more-services/ac_repairing_2.png" alt="AC Repairing" />
      </Col>
    </Row>
   
  </div>
);

export default AcRepairing;
