import React from "react";
import Breadcrumbs from "../components/BreadCrumbs";
import VideoArea from "../components/VideoArea";
import ServiceTabs from "../components/ServiceTabs";

function AcRepairing() {
  return (
    <div>
      {" "}
      <Breadcrumbs title={"Our Services"} pagename={"Home"} />
      <ServiceTabs active="AcrepairingService"  />
      <VideoArea />
    </div>
  );
}

export default AcRepairing;
