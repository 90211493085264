import React from 'react'
import Breadcrumbs from '../components/BreadCrumbs'
import VideoArea from '../components/VideoArea'
import ServiceTabs from '../components/ServiceTabs'

function ACInstallation() {
  return (
    <div><Breadcrumbs title={"Our Services"} pagename={"Home"} />
    <ServiceTabs />
    <VideoArea /></div>
  )
}

export default ACInstallation                 