import React from "react";
import { Link, useLocation } from "react-router-dom";
import ac1 from "../styles/ac2.png";
import "../styles/Breadcrumbs.css";
import { FaArrowRightLong } from "react-icons/fa6";
const Breadcrumbs = ({title, pagename}) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="row">
      <div className="col-12">
        <div
          className="page-title hello text-center"
          style={{ backgroundImage: `url(${ac1})`, height: "100%" }}
        >
          <h1 className="breadcrumb-title">{title}</h1>
          <div className="breadcrumb-menu">
            <ul className="trail-items">
              <li className="trail-item ">
                <Link to="/">{pagename}</Link>
                <FaArrowRightLong style={{ margin: "0px 15px" , color:"white" }} />
              </li>

              {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const isLast = index === pathnames.length - 1;
                return isLast ? (
                  <li key={name} className="trail-item trail-end">
                    <span>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
                  </li>
                ) : (
                  <li key={name} className="trail-item">
                    <Link to={routeTo}>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
