import React from 'react';
import { Col, Row } from 'react-bootstrap';

const AcOnRent = () => (
  <div>
    <h2>AC On Rent in Noida</h2>
    <p>
      Experience the comfort and convenience of our AC rental services in Noida. Whether you need a cooling solution for your home, office, or any event, we provide high-quality air conditioners on rent that cater to your specific needs. Our AC rental services ensure you stay cool and comfortable without the long-term commitment of purchasing an air conditioner.
    </p>
    <img src="/assets/service/more-services/ac_on_rent.png" alt="AC on Rent" />
    <p>
      Our rental process is straightforward and hassle-free. We offer flexible rental plans that suit both short-term and long-term requirements. Our team of experts ensures that the AC units are installed and maintained efficiently, providing you with uninterrupted cooling throughout the rental period.
    </p>
    <p>
      With a wide range of air conditioners available, we can accommodate any space and cooling requirement. From small rooms to large halls, our AC units are capable of delivering the perfect temperature control. Plus, our technicians are always ready to assist with any issues, ensuring your comfort is never compromised.
    </p>
    <h3>Service Overview</h3>
    <p>
      Our AC rental services in Noida include:
    </p>
    <ul className="styled-list">
      <li style={{listStyleType:"disc"}}>Wide selection of AC units to fit different spaces and requirements</li>
      <li style={{listStyleType:"disc"}}>Flexible rental plans for short-term and long-term needs</li>
      <li style={{listStyleType:"disc"}}>Professional installation and maintenance services</li>
      <li style={{listStyleType:"disc"}}>24/7 customer support for any technical issues</li>
      <li style={{listStyleType:"disc"}}>Regular maintenance and servicing to ensure optimal performance</li>
    </ul>
    <Row>
      <Col md={6}>
        <img src="/assets/service/more-services/ac_on-rent_1.png" alt="AC on Rent" />
      </Col>
      <Col md={6}>
        <img src="/assets/service/more-services/ac_on_rent_2.png" alt="AC on Rent" />
      </Col>
    </Row>
   
  </div>
);

export default AcOnRent;
