import React, { useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import "../styles/HeroSection.css";
import GetInTouchModal from "./GetInTouchModal";
// Install Swiper modules

const HeroSection = () => {
  const slides = [
    {
      img: "/ac1.png",
      title: "Expert AC Repair Services in Noida",
      subtitle: "Professional AC repair and installation solutions tailored for your needs.",
      button: "Request Callback",
    },
    {
      img: "/ac2.png",
      title: "AC Installation Specialists in Noida",
      subtitle: "Top-notch AC installation services ensuring efficient cooling solutions.",
      button: "Request Callback",
    },
    {
      img: "/ac3.png",
      title: "AC Rental Services in Noida",
      subtitle: "Flexible AC rental options to meet your cooling needs.",
      button: "Request Callback",
    },
    {
      img: "/ac4.png",
      title: "Expert Fridge Repair Services in Noida",
      subtitle: "Professional fridge repair services ensuring optimal cooling performance.",
      button: "Request Callback",
    },
    {
      img: "/ac5.png",
      title: "Washing Machine Repair Specialists in Noida",
      subtitle: "Reliable washing machine repair solutions tailored for your home.",
      button: "Request Callback",
    },
    {
      img: "/ac6.png",
      title: "Microwave Repair Services in Noida",
      subtitle: "Professional microwave repair services ensuring efficient cooking solutions.",
      button: "Request Callback",  
    },
  ];
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow=()=>{
    setShow(true)
  }
  return (
    <section className="slider-area fix">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div onClick={handleShow}
              className="single-slider slider-height d-flex align-items-center swiper-slide"
              style={{ backgroundImage: `url(${slide.img})` }}
            >
              <div className="custom-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="aslider z-index">
                      
                      <h1 className="aslider--title mb-30">{slide.title}</h1>
                      <h3 className="aslider--subtitle mb-50">
                        {slide.subtitle}
                      </h3>
                      <div className="aslider--btn">
                        <Link onClick={handleShow}
                          href="contact.html"
                          className="theme-btn theme-btn-orange"
                        >
                          {slide.button}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev slide-prev">
        <i className="far fa-long-arrow-left"></i>
      </div>
      <div className="swiper-button-next slide-next">
        <i className="far fa-long-arrow-right"></i>
      </div>
      {show && <GetInTouchModal show={show} handleClose={handleClose} setShow={setShow} />}
    </section>

  );
};

export default HeroSection;
