import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
function Services({ data, viewmore }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
      <section className="blog-area pt-110 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-70 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h1 className="section-title">
                  We provide most popular <br />
                  repair <span>services</span> In Noida
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            {data?.map((service) => (
              <div className="col-lg-4 col-md-6" key={service.id} style={{marginBottom:"25px"}}  data-aos="fade-up">
                <div
                  className={`ablog  wow fadeInUp`}
                  data-wow-delay={service.delay}
                >
                  <div className="ablog__img">
                    <img src={service.imgSrc} className="img-fluid" alt="img" />
                  </div>
                  <div className="ablog__text ablog__text--service">
                    <h4 className="ablog__text--title">
                      <a href={service.link}>{service.title}</a>
                    </h4>
                    <p>{service.description}</p>
                    <div className="ablog__btn">
                      <Link to={service.link} className="theme-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center ">
            {" "}
            <Link to="/services" style={{  fontSize:"20px" , fontFamily:"Nunito"}}>{viewmore}</Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
