import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function AboutCompany() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
      <section
        className="about-area about-back-bg pt-120 pb-85 wow fadeInUp"
        data-wow-delay=".6s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6"  >
              <div
                className="aabout-img-space2 mb-35 position-relative mr-70 z-index wow fadeInUp"
                data-wow-delay=".3s"
                data-aos="fade-up"

              >
                <img src="/all_applines.png" className="img-fluid" alt="img" />
                <div className="aabout__since d-flex align-items-center justify-content-center">
                  <div>
                    <span>Since</span>
                    <h3>2017</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up">
              <div className="aabout-text aabout-text2 mb-35 z-index">
                <div
                  className="section-title-wrapper mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <h2 className="section-title">
                    We are most popular repair <span>company</span> In Noida
                  </h2>
                </div>
                <p className="mb-40 wow fadeInUp" data-wow-delay=".5s">
                  Our reputation as the most popular repair company is built on
                  our unwavering dedication to providing outstanding service and
                  ensuring complete customer satisfaction with every repair we
                  undertake.{" "}
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className="aabout--profile aabout--profile2 mb-45 wow fadeInUp"
                      data-wow-delay=".8s"
                    >
                      <div className="aabout--profile__img">
                        <img
                          src="/assets/1.png"
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div className="aabout--profile__text aabout--profile__text2">
                        <h4>
                          Superfast <br />
                          Reliable Service
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="aabout--profile aabout--profile2 mb-45 wow fadeInUp"
                      data-wow-delay="1.1s"
                    >
                      <div className="aabout--profile__img">
                        <img
                          src="/assets/2.png"
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div className="aabout--profile__text aabout--profile__text2">
                        <h4>
                          Properly <br />
                          Repair Solution
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div
                      className="aabout--profile__number grey-bg mb-45 wow fadeInUp"
                      data-wow-delay="1.4s"
                    >
                      <span>Free Consultacy Now</span>
                      <h4>
                        <a href="tel:9990292149">+91 9990292149 </a>
                      </h4>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div
                      className="aabout--profile__para mb-45 wow fadeInUp"
                      data-wow-delay="1.7s"
                    >
                      <p>
                      Our repair company specializes in delivering efficient solutions and reliable service, ensuring your appliances operate at their best.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="aabout--profile__wrapper wow fadeInUp"
                  data-wow-delay="2s"
                >
                  <div className="aabout--btn">
                    <Link to="/about" className="theme-btn" style={{listStyle:"none", textDecoration:"none"}}>Read More</Link>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutCompany;
