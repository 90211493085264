import React, { useEffect, useState } from "react";
import "../styles/contact.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function ContactUsForm() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Mobile_Number: "",
    Description: "",
    Location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const googleFormsUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeYYgHAeOOmnJ5oJ2LzbMdX89hDY-aW6zzc5fOo9p7DU7CZdw/formResponse";
    // Map form data to Google Forms entry IDs
    const formParams = new URLSearchParams({
      "entry.2070711827": formData.Name,
      "entry.285480210": formData.Name,
      "entry.1361260351": formData.Mobile_Number,
      "entry.1709632575": formData.Location,
      "entry.738628135": formData.Description,
    }).toString();
    const fullUrl = `${googleFormsUrl}?${formParams}`;

    fetch(fullUrl, {
      method: "POST",
      mode: "no-cors", 
    })
      .then((response) => {
        toast.success("Form submitted successfully!");

        e.target.reset();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="custom-container">
      <Row style={{ margin: '80px 0' }}>
      <Col md={6}>
        <img
          src="/about_1.png"
          alt="About"
          style={{ height: '100%', width: '100%', maxHeight: '700px' }}
        />
      </Col>
      <Col md={6}>
        <div className="contact__inner--form service__contact--form">
          <form className="form" onSubmit={handleSubmit}>
            <input
              placeholder="Your Name"
              name="Name"
              type="text"
              value={formData.Name}
              onChange={handleChange}
              required
            />
            <input
              placeholder="Your Email"
              name="Email"
              type="text"
              value={formData.Email}
              onChange={handleChange}
              required
            />
            <input
              placeholder="Your Phone"
              name="Mobile_Number"
              type="text"
              value={formData.Mobile_Number}
              onChange={handleChange}
              required
            />
            <input
              placeholder="Your Message"
              name="Description"
              type="text"
              value={formData.Description}
              onChange={handleChange}
            />
            <input
              placeholder="Your Location"
              name="Location"
              type="text"
              value={formData.Location}
              onChange={handleChange}
              required
            />
            <button type="submit" className="theme-btn theme-btn-blue">
              Submit
            </button>
          </form>
        </div>
      </Col>
    </Row>
    </div>
  );
}

export default ContactUsForm;
