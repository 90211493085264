import React from "react";
import NavBar from "./components/Navbar";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Service from "./pages/Services";
import AcRepairing from "./pages/ACRepairing";
import WashingMachine from "./pages/WashingMachine";
import FridgeRepairing from "./pages/FridgeRepairing";
import ACInstallation from "./pages/Ac-Installation";
import Microwave from "./pages/MicroWave";
import AcOnRent from "./pages/AconRent";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/footer";
import PageNotFound from "./pages/404";

function App() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Service />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/ac-repair" element={<AcRepairing />} />
        <Route path="/services/fridge-repair" element={<FridgeRepairing />} />
        <Route path="/services/washing-machine-repair" element={<WashingMachine />} />
        <Route path="/services/ac-installaton" element={<ACInstallation />} />
        <Route path="/services/microwave" element={<Microwave />} />
        <Route path="/services/ac-rental" element={<AcOnRent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
